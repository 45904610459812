import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(state) {
		const params = {
			order_by: 'created_at',
			order: 'desc',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'state',
			'filters[0][operator]': '=',
			'filters[0][value]': state,
		}
		const { data } = await useJwt.get(API_ROUTES.maintenanceService.get, params)

		return data
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.maintenanceService.update}/${id}`, data)
	},
}
